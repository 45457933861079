export const Colors = {
  basic: {
    black: '#171717',
    dark: '#1d1d1d',
    white: '#FFFFFF',
    grey: '#d3d3d3',
    transparent: 'transparent',
  },
  pride: {
    purple: '#A275E3',
    blue: '#4D96FF',
    green: '#6BCB77',
    yellow: '#FFE26F',
    orange: '#FA9856',
    red: '#FF6B6B',
  },
};
