import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { Colors } from '../assets/colors/scheme'
import { Box } from '../components/Box'


export const Main: React.FC = () => {
  return (
    <View style={styles.screen}>
      <Box />
    </View>
  )
}

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: Colors.basic.dark,
    justifyContent: 'center',
    alignItems: 'center',
  }
})