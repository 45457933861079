import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React from 'react'
import { Colors } from '../assets/colors/scheme'
import { Fonts } from '../assets/fonts/fonts'

interface ButtonProps {
  onPress: () => void,
  text: string,
  icon?: React.ReactElement
}

export const Button: React.FC<ButtonProps> = ({ onPress, text, icon }) => {
  return (
    <TouchableOpacity style={styles.button} onPress={onPress}>
      {icon &&
        (React.cloneElement(icon))
      }
      <Text style={styles.text}>{text}</Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  button: {
    width: 195,
    height: 40,
    paddingLeft: 30,
    alignItems: 'center',
    borderRadius: 25,
    borderWidth: 1,
    borderColor: Colors.basic.white,
    flexDirection: 'row',
    shadowColor: Colors.basic.grey,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 8.30,
    elevation: 13,
  },
  text: {
    color: Colors.basic.white,
    marginLeft: 10,
    fontSize: 16,
    fontFamily: Fonts.PoppinsLight
  }
})