import { Dimensions, Image, Linking, Platform, StyleSheet, Text, View } from 'react-native'
import React, { Fragment, useCallback } from 'react'
import { Colors } from '../assets/colors/scheme'
import { Linkedin } from '../assets/icons/Linkedin'
import { Button } from './Button'
import { Fonts } from '../assets/fonts/fonts'


const device = Dimensions.get('window')
const small = device.width <= 400

export const Box: React.FC = () => {

  const onPress = useCallback(() => {
    Linking.openURL('https://www.linkedin.com/in/veronique-cacho/')
  }, [])
  return (

    <View style={styles.container}>
      <View style={styles.content}>
        <Image source={require('../assets/images/v.jpeg')} style={styles.image} />

        <Text style={styles.title}>Veronique Cacho</Text>
        {small ?
          <View style={styles.smallTextContainer}>
            <Text style={styles.subTitle}>Copenhagen, Denmark </Text>
            <Text style={styles.subTitle}>BA in Software Development </Text>
          </View>
          : <Text style={styles.subTitle}>Copenhagen, Denmark  -  BA in Software Development </Text>}
        <Text style={styles.text}>Explore My Professional Journey!</Text>
        {small ? <Text style={styles.description}>{`Discover the chapters of my\ncareer as a Developer, contributing\nto cross-platform applications`}</Text> :
          <Text style={styles.description}>{`Discover the chapters of my career as a Developer,\ncontributing to cross-platform applications`}</Text>}
        <Button onPress={onPress} text={`Let's connect`} icon={<Linkedin size={17} />} />
      </View>

    </View >
  )
}

const styles = StyleSheet.create({
  container: {
    borderTopWidth: 10,
    borderBottomWidth: 10,
    borderColor: Colors.pride.red,
    width: small ? 200 : 280,
    height: small ? 470 : 420,
    alignItems: 'center',
    borderRadius: 25
  },
  content: {
    width: small ? 300 : 400,
    height: small ? 450 : 400,
    borderRadius: 25,
    backgroundColor: Colors.basic.black,
    alignItems: 'center',
    justifyContent: 'space-evenly',
    borderWidth: 1,
    borderColor: Colors.pride.red,
  },

  title: {
    color: Colors.basic.white,
    fontFamily: Fonts.PoppinsBlack,
    fontSize: 17,
    letterSpacing: 1,
    cursor: 'default'
  },
  subTitle: {
    color: Colors.basic.grey,
    fontFamily: Fonts.PoppinsSemiBold,
    fontSize: 12,
    letterSpacing: 0.4,
    cursor: 'default'
  },
  text: {
    color: Colors.basic.white,
    fontFamily: Fonts.PoppinsSemiBold,
    fontSize: 14,
    letterSpacing: 0.4,
    cursor: 'default'
  },
  description: {
    color: Colors.basic.white,
    fontFamily: Fonts.PoppinsMedium,
    fontSize: 12,
    letterSpacing: 0.4,
    textAlign: 'center',
    cursor: 'default'
  },
  image: {
    width: 120,
    height: 120,
    borderRadius: 75,
  },
  smallTextContainer: {
    alignItems: 'center',
    marginTop: -10
  }

})