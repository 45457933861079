import React from 'react'
import { Circle, Path, Svg } from 'react-native-svg'
import { Colors } from '../colors/scheme';

interface IconProps {
  size?: number;
  stroke?: string;
}

export const Linkedin: React.FC<IconProps> = ({
  size = 24, stroke = Colors.basic.white
}) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={stroke}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <Path d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6z" />
      <Path d="M2 9H6V21H2z" />
      <Circle cx={4} cy={4} r={2} />
    </Svg>
  )
}
